import React, { useEffect, useRef, useState, useCallback } from "react";
import { fromEvent, merge, timer } from "rxjs";
import { tap, takeUntil, switchMap } from "rxjs/operators";

import styles from "./participant.scss";
import { RemoteParticipant } from "./RemoteParticipant";
import { PartyGoer } from "./PartyGoer";
import { Modal, Rating, Button } from "semantic-ui-react";

type Props = {
	partyGoer: PartyGoer;
	isHighlighted: boolean;
	isMuted: boolean;
	onDataMessageReceived: (partyGoerId: string, data: string) => void;
	onLongPressStart: (partyGoerId: string) => void;
	onLongPressEnd: (partyGoerId: string) => void;
	timeOutTarget: (targetUid: string) => void;
};

const stopPropogation = (e: Event) => {
	e.preventDefault();
};

function getInteractionObservables(el: HTMLDivElement) {
	const thresholdToLongTap = timer(200);

	const touchStarts = fromEvent(el, "touchstart").pipe(tap(stopPropogation));
	const touchEnds = fromEvent(el, "touchend").pipe(tap(stopPropogation));
	const mouseDowns = fromEvent(el, "mousedown").pipe(tap(stopPropogation));
	const mouseUps = fromEvent(el, "mouseup").pipe(tap(stopPropogation));

	const startPresses = merge(touchStarts, mouseDowns);
	const endPresses = merge(touchEnds, mouseUps);

	const clicks = startPresses.pipe(
		switchMap(() => endPresses.pipe(takeUntil(thresholdToLongTap))),
	);

	const longPressStarts = startPresses.pipe(
		switchMap(() => thresholdToLongTap.pipe(takeUntil(endPresses))),
	);

	return {
		underlying: {
			touchStarts,
			touchEnds,
			mouseDowns,
			mouseUps,
			startPresses,
		},
		clicks,
		longPressStarts,
		endPresses,
	};
}

export const PartyGoerEmbodiment: React.FC<Props> = (props) => {
	const embodimentRef = useRef<HTMLDivElement>(null);
	const [isLongPressing, setIsLongPressing] = useState(false);
	const [showingOptions, setShowingOptions] = useState(false);

	const id = props.partyGoer.id;
	const { onLongPressEnd, onLongPressStart, onDataMessageReceived } = props;

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const el = embodimentRef.current!;

		const { clicks } = getInteractionObservables(el);

		const sub = clicks.subscribe(() => setShowingOptions(true));
		return () => sub.unsubscribe();
	}, []);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const el = embodimentRef.current!;

		const { longPressStarts } = getInteractionObservables(el);

		const sub = longPressStarts.subscribe(() => {
			setIsLongPressing(true);
		});
		return () => sub.unsubscribe();
	}, []);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const el = embodimentRef.current!;

		const { endPresses } = getInteractionObservables(el);

		const sub = endPresses.subscribe(() => {
			setIsLongPressing(false);
		});
		return () => sub.unsubscribe();
	}, []);

	useEffect(() => {
		if (isLongPressing) {
			onLongPressStart(id);

			return () => onLongPressEnd(id);
		}
	}, [id, isLongPressing, onLongPressEnd, onLongPressStart]);

	const messageReceivedCallback = useCallback(
		(data: string) => {
			onDataMessageReceived(id, data);
		},
		[id, onDataMessageReceived],
	);

	const participant = props.partyGoer.participant;
	return (
		<>
			<Modal
				size="mini"
				open={showingOptions}
				onClose={() => setShowingOptions(false)}
			>
				<Modal.Content>
					Network Quality Level{" "}
					<Rating
						disabled
						rating={participant.networkQualityLevel ?? 0}
						maxRating={5}
					/>
				</Modal.Content>
				<Modal.Actions>
					<Button
						color="red"
						onClick={() => {
							props.timeOutTarget(props.partyGoer.id);
							setShowingOptions(false);
						}}
					>
						Time Out for 30s (6 points)
					</Button>
				</Modal.Actions>
			</Modal>
			<div
				ref={embodimentRef}
				className={styles.participant}
				title={props.partyGoer.id}
			>
				<RemoteParticipant
					participant={participant}
					onDataMessageReceived={messageReceivedCallback}
					isHighlighted={props.isHighlighted}
					isMuted={props.isMuted}
				/>
			</div>
		</>
	);
};

PartyGoerEmbodiment.displayName = "PartyGoerEmbodiment";
