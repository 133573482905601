import React from "react";
import { useAuth } from "./firebase/FirebaseContext";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import { auth as firebaseAuth } from "firebase/app";

import appStyles from "./app.scss";
import { Header } from "./Header";

export const Login: React.FC = () => {
	const signInUiConfig: firebaseui.auth.Config = {
		signInOptions: [firebaseAuth.PhoneAuthProvider.PROVIDER_ID],
		callbacks: {
			// Avoid redirects after sign-in.
			signInSuccessWithAuthResult: (): boolean => false,
		},
	};

	const auth = useAuth();

	return (
		<div className={appStyles.app}>
			<Header />
			<main>
				<StyledFirebaseAuth uiConfig={signInUiConfig} firebaseAuth={auth} />
			</main>
		</div>
	);
};

Login.displayName = "Login";
