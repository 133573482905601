import React, { useEffect, useState } from "react";
import { Menu, Label } from "semantic-ui-react";

import { useFirestore } from "./firebase/FirebaseContext";
import { useUserState } from "./firebase/AuthStateContext";

export const PointsMenu = () => {
	const userState = useUserState();
	const db = useFirestore();

	const [points, setPoints] = useState<number>();

	useEffect(() => {
		if (userState === "anonymous" || userState === "loading") {
			return;
		}

		const uid = userState.uid;
		const userDocRef = db.collection("users").doc(uid);
		return userDocRef.onSnapshot((snapshot) => {
			const data = snapshot.data();
			const pointsOnRecord = (data?.points ?? 0) as number;
			setPoints(pointsOnRecord);
		});
	});

	if (userState === "anonymous" || userState === "loading" || points == null) {
		return <></>;
	}

	return (
		<Menu.Item name="Points">
			Points: <Label color="black">{points}</Label>
		</Menu.Item>
	);
};

PointsMenu.displayName = "PointsMenu";
