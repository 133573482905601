import React from "react";
import { UserContextProvider } from "./UserContext";
import { useUserState } from "./AuthStateContext";

type Props = {
	loginPage: React.ReactNode;
	loading: React.ReactNode;
	children: React.ReactNode;
};

export const LoginRequiredProvider: React.FC<Props> = (props) => {
	const userState = useUserState();

	switch (userState) {
		case "loading":
			return <>{props.loading}</>;
		case "anonymous":
			return <>{props.loginPage}</>;
		default:
			return (
				<UserContextProvider value={{ user: userState }}>
					{props.children}
				</UserContextProvider>
			);
	}
};
LoginRequiredProvider.displayName = "LoginRequiredProvider";
