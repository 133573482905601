import React, { useState } from "react";
import Video from "twilio-video";

import { ParticipantAV } from "./ParticipantAV";

import styles from "./participant.scss";
import { Modal, Rating } from "semantic-ui-react";

type LocalParticipantProps = {
	videoTrack?: Video.LocalVideoTrack;
	networkQualityLevel: number | undefined;
};

export const LocalParticipant = (props: LocalParticipantProps) => {
	const [showingOptions, setShowingOptions] = useState(false);
	return (
		<>
			<Modal
				size="mini"
				open={showingOptions}
				onClose={() => setShowingOptions(false)}
			>
				<Modal.Content>
					Network Quality Level{" "}
					<Rating
						disabled
						rating={props.networkQualityLevel ?? 0}
						maxRating={5}
					/>
				</Modal.Content>
			</Modal>
			<div
				className={styles.participant}
				onClick={() => setShowingOptions(true)}
			>
				<ParticipantAV
					videoTrack={props.videoTrack}
					isHighlighted={false}
					usePoster={false}
				/>
			</div>
		</>
	);
};

LocalParticipant.displayName = "LocalParticipant";
