import React, { useEffect, useState } from "react";
import { useFirestore } from "../firebase/FirebaseContext";
import { Container, Table, Form } from "semantic-ui-react";
import { functions } from "firebase/app";

interface PointsRecord {
	uid: string;
	points: number;
}

const PointsAdmin: React.FC = () => {
	const [users, setUsers] = useState<PointsRecord[]>();
	const [granteeUid, setGranteeUid] = useState("");
	const [granteePoints, setGranteePoints] = useState(0);
	const db = useFirestore();

	useEffect(() => {
		return db.collection("users").onSnapshot((usersSnap) => {
			const dbUsers = usersSnap.docs.map(
				(u): PointsRecord => {
					return {
						uid: u.id,
						points: u.data().points,
					};
				},
			);

			setUsers(dbUsers);
		});
	}, [db]);

	const submit = async () => {
		const func = functions().httpsCallable("grant_points");
		const args = { uid: granteeUid, points: granteePoints };
		await func(args);
	};

	let rows;
	if (users == null) {
		rows = (
			<Table.Row>
				<Table.Cell>Loading...</Table.Cell>
				<Table.Cell></Table.Cell>
			</Table.Row>
		);
	} else {
		rows = users.map((u) => {
			return (
				<Table.Row key={u.uid}>
					<Table.Cell>{u.uid}</Table.Cell>
					<Table.Cell>{u.points}</Table.Cell>
				</Table.Row>
			);
		});
	}
	return (
		<Container>
			<Form onSubmit={() => submit()}>
				<Form.Input
					label="UID"
					onChange={(_e, data) => setGranteeUid(data.value)}
				/>
				<Form.Input
					label="Points"
					type="number"
					onChange={(_e, data) => setGranteePoints(parseInt(data.value, 10))}
				/>
				<Form.Button content="Submit" />
			</Form>
			<Table>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>UID</Table.HeaderCell>
						<Table.HeaderCell>Points</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>{rows}</Table.Body>
			</Table>
		</Container>
	);
};

export { PointsAdmin };
