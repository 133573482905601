import React from "react";
import { GridViewport } from "./rooms/GridViewport";

type MapProps = {};

export const MapComponent: React.FC<MapProps> = (props) => {
	return <GridViewport>{props.children}</GridViewport>;
};

MapComponent.displayName = "Map";
