import React from "react";

import { Header } from "../Header";

import appStyles from "../app.scss";
import { PointsAdmin } from "./PointsAdmin";
import { InviteAdmin } from "./InviteAdmin";

type Props = {
	toggleAdminView: () => void;
};
const AdminView: React.FC<Props> = (props) => {
	return (
		<div className={appStyles.app}>
			<Header toggleAdminView={props.toggleAdminView} />
			<main>
				<PointsAdmin />
				<InviteAdmin />
			</main>
		</div>
	);
};

export { AdminView };
