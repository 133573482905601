import { firestore } from "firebase/app";
import {
	InvitationStatus,
	Invitation,
	ExtendedInvitation,
	RequestedInvitation,
} from "../../lib/invitationTypes";

type ClientDb = firestore.Firestore;

export function parseSnapshot(
	snapshot: firestore.QueryDocumentSnapshot<firestore.DocumentData>,
): Invitation {
	const invitationRef = snapshot.ref.path;
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const partyId = snapshot.ref.parent.parent!.id;
	const status = snapshot.data().status as InvitationStatus;

	return {
		invitationRef,
		status,
		partyId,
	};
}

export async function inviteGuest(
	db: ClientDb,
	phoneNumber: string,
	partyId: string,
): Promise<void> {
	await db
		.collection("parties")
		.doc(partyId)
		.collection("invitations")
		.doc(phoneNumber)
		.set({
			status: InvitationStatus.EXTENDED,
			phoneNumber: phoneNumber,
		});
}

export async function acceptInvitation(
	db: ClientDb,
	invitation: ExtendedInvitation,
): Promise<void> {
	await db.doc(invitation.invitationRef).update({
		status: InvitationStatus.ACCEPTED,
	});
}

export async function grantRequestedInvitation(
	db: ClientDb,
	invitation: RequestedInvitation,
): Promise<void> {
	await db.doc(invitation.invitationRef).update({
		status: InvitationStatus.ACCEPTED,
	});
}
