import React from "react";

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import { useAuth } from "./FirebaseContext";
import { AuthStateContextProvider } from "./AuthStateContext";

export const FirebaseAuthStateProvider: React.FC = (props) => {
	const [userState, setUserState] = React.useState<
		"loading" | "anonymous" | firebase.User
	>("loading");

	const auth = useAuth();

	React.useEffect(() => {
		return auth.onAuthStateChanged((user: firebase.User | null) => {
			if (user == null) {
				setUserState("anonymous");
			} else {
				setUserState(user);
			}
		});
	}, [auth]);

	return (
		<AuthStateContextProvider value={{ userState }}>
			{props.children}
		</AuthStateContextProvider>
	);
};
FirebaseAuthStateProvider.displayName = "FirebaseAuthStateProvider";
