import React, { useState, useCallback } from "react";

import { Lobby } from "./Lobby";
import { Party } from "./Party";
import { AdminView } from "./admin/AdminView";

export const Main: React.FC = () => {
	const [partyId, setPartyId] = useState<string>();
	const [inAdminView, setInAdminView] = useState(false);

	const leaveParty = useCallback(() => {
		setPartyId(() => undefined);
	}, []);

	const enterParty = useCallback((partyId: string) => {
		setPartyId(() => partyId);
	}, []);

	const toggleAdminView = useCallback(
		() => setInAdminView((curr) => !curr),
		[],
	);

	if (inAdminView) {
		return <AdminView toggleAdminView={toggleAdminView} />;
	}

	if (partyId == null) {
		return (
			<Lobby enterParty={enterParty} toggleAdminView={toggleAdminView}></Lobby>
		);
	}

	return <Party partyId={partyId} leaveParty={leaveParty}></Party>;
};
Main.displayName = "Main";
