import { useCallback, useState } from "react";

export type WhisperMessage = StartWhisperMessage | EndWhisperMessage;

export interface WhisperState {
	iAmWhisperingTo: "nobody" | { uid: string };
	roomWhispers: Whisper[];
}

export interface Whisper {
	fromUid: string;
	to: "me" | { uid: string };
}

interface StartWhisperMessage {
	type: "whisper";
	state: "started";
	toUid: string;
}

interface EndWhisperMessage {
	type: "whisper";
	state: "ended";
}

export function isWhisperMessage(message: unknown): message is WhisperMessage {
	return (message as WhisperMessage).type === "whisper";
}

const updateStateOnWhisperMessageReceived = (
	myUid: string,
	currentState: WhisperState,
	message: WhisperMessage,
	receivedFromUid: string,
): WhisperState => {
	const updatedState = {
		...currentState,
		roomWhispers: currentState.roomWhispers.filter(
			(w) => w.fromUid !== receivedFromUid,
		),
	};

	switch (message.state) {
		case "started":
			updatedState.roomWhispers.push({
				fromUid: receivedFromUid,
				to: message.toUid === myUid ? "me" : { uid: message.toUid },
			});
			break;
		case "ended":
			break;
	}

	return updatedState;
};

const updateStateOnInitiateWhisper = (
	currentState: WhisperState,
	whisperingToUid: string,
): WhisperState => {
	return {
		...currentState,
		iAmWhisperingTo: { uid: whisperingToUid },
	};
};

const updateStateOnEndWhisper = (currentState: WhisperState): WhisperState => {
	return {
		...currentState,
		iAmWhisperingTo: "nobody",
	};
};

const updateStateOnParticipantDisconnect = (
	currentState: WhisperState,
	disconnectingUid: string,
): WhisperState => {
	return {
		...currentState,
		roomWhispers: currentState.roomWhispers.filter(
			(w) => w.fromUid !== disconnectingUid,
		),
	};
};

const getMessageObjectFromState = (state: WhisperState): WhisperMessage => {
	const myState = state.iAmWhisperingTo;
	if (myState === "nobody") {
		return { type: "whisper", state: "ended" };
	}

	return { type: "whisper", state: "started", toUid: myState.uid };
};

const getDataMessageFromState = (state: WhisperState): string => {
	const object = getMessageObjectFromState(state);
	return JSON.stringify(object);
};

const initialState: WhisperState = {
	roomWhispers: [],
	iAmWhisperingTo: "nobody",
};

export function useWhispering(myUid: string) {
	const [whisperState, setWhisperState] = useState<WhisperState>(initialState);

	const onWhisperParticipantDisconnected = useCallback(
		(disconnectingUid: string): void => {
			setWhisperState((curr) =>
				updateStateOnParticipantDisconnect(curr, disconnectingUid),
			);
		},
		[],
	);

	const onWhisperChangeRooms = useCallback((): void => {
		setWhisperState(() => initialState);
	}, []);

	const onWhisperMessageReceived = useCallback(
		(message: WhisperMessage, receivedFromUid: string): void => {
			setWhisperState((curr) => {
				return updateStateOnWhisperMessageReceived(
					myUid,
					curr,
					message,
					receivedFromUid,
				);
			});
		},
		[myUid],
	);

	const startWhispering = useCallback(
		(
			toUid: string,
			sendMessage: ((message: string) => void) | undefined,
		): void => {
			if (sendMessage == null) {
				// can't start whispering with a way to send messages
				return;
			}
			setWhisperState((curr) => {
				const newState = updateStateOnInitiateWhisper(curr, toUid);
				const message = getDataMessageFromState(newState);
				sendMessage(message);
				return newState;
			});
		},
		[],
	);

	const stopWhispering = useCallback(
		(sendMessage: ((message: string) => void) | undefined): void => {
			if (sendMessage == null) {
				// can't stop whispering with a way to send messages
				return;
			}
			setWhisperState((curr) => {
				const newState = updateStateOnEndWhisper(curr);
				const message = getDataMessageFromState(newState);
				sendMessage(message);
				return newState;
			});
		},
		[],
	);

	const amIWhisperingTo = useCallback(
		(uid: string): boolean => {
			const { iAmWhisperingTo } = whisperState;
			return iAmWhisperingTo !== "nobody" && iAmWhisperingTo.uid === uid;
		},
		[whisperState],
	);

	const whoAreTheyWhisperingTo = useCallback(
		(uid: string): "me" | "nobody" | { uid: string } => {
			const theirWhisper = whisperState.roomWhispers.find(
				(w) => w.fromUid === uid,
			);

			if (theirWhisper == null) {
				return "nobody";
			}

			return theirWhisper.to;
		},
		[whisperState],
	);

	return {
		onWhisperChangeRooms,
		onWhisperParticipantDisconnected,
		onWhisperMessageReceived,
		amIWhisperingTo,
		whoAreTheyWhisperingTo,
		startWhispering,
		stopWhispering,
	};
}
