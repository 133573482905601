import React, { useState, useEffect } from "react";
import Video from "twilio-video";

import { ParticipantAV } from "./ParticipantAV";

type Props = {
	participant: Video.RemoteParticipant;
	onDataMessageReceived: (data: string) => void;
	isHighlighted: boolean;
	isMuted: boolean;
};

function notNull<TValue>(value: TValue | null | undefined): value is TValue {
	return value !== null && value !== undefined;
}

export const RemoteParticipant: React.FC<Props> = (props) => {
	const [videoTracks, setVideoTracks] = useState<Video.VideoTrack[]>([]);
	const [audioTracks, setAudioTracks] = useState<Video.AudioTrack[]>([]);

	useEffect(() => {
		setVideoTracks(
			Array.from(props.participant.videoTracks.values())
				.map((publication) => publication.track)
				.filter(notNull),
		);
		setAudioTracks(
			Array.from(props.participant.audioTracks.values())
				.map((publication) => publication.track)
				.filter(notNull),
		);

		const trackSubscribed = (track: Video.RemoteTrack) => {
			if (track.kind === "video") {
				setVideoTracks((videoTracks) => [...videoTracks, track]);
			} else if (track.kind === "audio") {
				setAudioTracks((audioTracks) => [...audioTracks, track]);
			}
		};

		const trackUnsubscribed = (track: Video.RemoteTrack) => {
			if (track.kind === "video") {
				setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
			} else if (track.kind === "audio") {
				setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
			}
		};

		const handleDataTrackMessage = (
			data: string,
			_track: Video.RemoteDataTrack,
		) => {
			props.onDataMessageReceived?.(data);
		};

		props.participant.on("trackSubscribed", trackSubscribed);
		props.participant.on("trackUnsubscribed", trackUnsubscribed);
		props.participant.on("trackMessage", handleDataTrackMessage);

		return () => {
			setVideoTracks([]);
			setAudioTracks([]);
			props.participant.removeAllListeners();
		};
	}, [props.onDataMessageReceived, props.participant]);

	return (
		<>
			<ParticipantAV
				videoTrack={videoTracks[0]}
				audioTrack={audioTracks[0]}
				isHighlighted={props.isHighlighted}
				isMuted={props.isMuted}
			/>
		</>
	);
};

RemoteParticipant.displayName = "RemoteParticipant";
