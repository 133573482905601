import React from "react";
import Video from "twilio-video";
import { captureException } from "@sentry/browser";
import { CapturedError } from "../util/CapturedError";
import LocalTracks from "./LocalTracks";

async function createLocalTracks() {
	const localTracks = await Video.createLocalTracks();

	const audioTrack = localTracks.find(
		(t) => t.kind === "audio",
	) as Video.LocalAudioTrack;

	const videoTrack = localTracks.find(
		(t) => t.kind === "video",
	) as Video.LocalVideoTrack;

	return { audioTrack, videoTrack };
}

const trackCreationErrorHandler = (
	error: Error,
	onError: (error: Error) => void,
) => {
	const eventId = captureException(error);
	const errorTitle = `Webcam / Microphone Issue`;
	const domError = error as DOMError;
	if (domError && domError.name === "NotAllowedError") {
		onError(
			new CapturedError(
				errorTitle,
				`Permission to use your camera and microphone is required. Please change your browser's permission settings and refresh the page. Contact Adam if you need help!`,
				eventId,
			),
		);
		return undefined;
	}
	if (domError && domError.name === "NotReadableError") {
		onError(
			new CapturedError(
				errorTitle,
				`We hand an issue connecting using your microphone and camera. Please make sure that you have them, and that they're plugged in and working. Contact Adam if you need help!`,
				eventId,
			),
		);
		return undefined;
	}

	onError(
		new CapturedError(
			errorTitle,
			`We hand an issue connecting using your microphone and camera. Contact Adam if you need help!`,
			eventId,
		),
	);
	return undefined;
};

export default function useLocalTracks(onError: (error: Error) => void) {
	const [audioTrack, setAudioTrack] = React.useState<Video.LocalAudioTrack>();
	const [videoTrack, setVideoTrack] = React.useState<Video.LocalVideoTrack>();
	const [dataTrack, _setDataTrack] = React.useState<Video.LocalDataTrack>(
		new Video.LocalDataTrack(),
	);

	React.useEffect(() => {
		const promiseToCreate = createLocalTracks()
			.then((tracks) => {
				setAudioTrack(tracks.audioTrack);
				setVideoTrack(tracks.videoTrack);
				return tracks;
			})
			.catch((error: Error) => trackCreationErrorHandler(error, onError));
		return (): void => {
			promiseToCreate.then((tracks) => {
				if (tracks == null) {
					return;
				}

				// stopping tracks
				tracks.videoTrack.stop();
				tracks.audioTrack.stop();
			});
		};
	}, [onError]);

	const localTracks = React.useMemo(() => {
		if (audioTrack != null && videoTrack != null && dataTrack != null) {
			return { audio: audioTrack, video: videoTrack, data: dataTrack };
		}

		return undefined;
	}, [audioTrack, dataTrack, videoTrack]);

	const getLocalTracks = React.useCallback((): LocalTracks | undefined => {
		return localTracks;
	}, [localTracks]);

	// const hasLocalTracks = React.useCallback((): boolean => {
	// 	if (audioTrack != null && videoTrack != null && dataTrack != null) {
	// 		return true;
	// 	}

	// 	return false;
	// }, [audioTrack, dataTrack, videoTrack]);

	return { getLocalTracks };
}
