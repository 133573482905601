import React from "react";

import { functions } from "firebase/app";

import useLocalTracks from "./av/useLocalTracks";
import { useDrinkPouring } from "./games/pouring";

import kitchenPng from "./images/kitchen.png";
import livingRoomJpg from "./images/living-room.jpg";
import patioPng from "./images/patio.png";
import lockerJpg from "./images/locker-canada.jpg";

import appStyles from "./app.scss";
import { ErrorBoundary } from "./ErrorBoundary";
import { Header } from "./Header";
import { RoomSelectorProps } from "./RoomSelector";
import { Room } from "./Room";
import { useTimingOut } from "./games/timingOut";

const backgroundMappings = new Map([
	["SIDC:LivingRoom", livingRoomJpg],
	["SIDC:Kitchen", kitchenPng],
	["SIDC:Patio", patioPng],
	["SIDC:LockerRoom", lockerJpg],
]);

const getBackgroundLocation = (roomId?: string): string | undefined => {
	if (!roomId) return undefined;
	return backgroundMappings.get(roomId) ?? livingRoomJpg;
};

type PartyProps = {
	partyId: string;
	leaveParty: () => void;
};

export const Party: React.FC<PartyProps> = (props: PartyProps) => {
	const [error, setError] = React.useState<Error>();
	if (error != null) {
		throw error;
	}

	const onError = React.useCallback((error: Error) => setError(error), []);
	const [token, setToken] = React.useState<string>();
	const [roomId, setRoomId] = React.useState<string>("SIDC:LivingRoom");

	const { getLocalTracks } = useLocalTracks(onError);

	const { pourDrinkButtonProps, drinkPourResultsModal } = useDrinkPouring(
		props.partyId,
		roomId,
		getLocalTracks,
		onError,
	);

	const {
		timeOutAttackTarget,
		timeOutResultModal,
		timeOutInEffect,
	} = useTimingOut(props.partyId, roomId, onError);

	React.useEffect(() => {
		const getVideoTokenForParty = functions().httpsCallable(
			"getVideoTokenForParty",
		);

		const requestedPartyId = props.partyId;
		getVideoTokenForParty({ partyId: requestedPartyId }).then((result) => {
			setToken((currentToken) => {
				// don't overwrite the token if we've switched parties
				if (props.partyId === requestedPartyId) {
					return result.data.token;
				}

				return currentToken;
			});
		});
	}, [props.partyId]);

	const roomSelectorProps = React.useMemo((): RoomSelectorProps => {
		return {
			partyId: props.partyId,
			leaveParty: props.leaveParty,
			roomId,
			setRoomId,
		};
	}, [props.leaveParty, props.partyId, roomId]);

	const backgroundLocation = getBackgroundLocation(roomId);

	return (
		<div className={appStyles.app}>
			<Header
				pourDrink={pourDrinkButtonProps}
				roomSelector={roomSelectorProps}
			/>
			<main
				style={{
					backgroundImage:
						backgroundLocation != null ? `url(${backgroundLocation})` : "",
				}}
			>
				<ErrorBoundary>
					{drinkPourResultsModal}
					{timeOutResultModal}
					<Room
						roomId={roomId}
						token={token}
						getLocalTracks={getLocalTracks}
						timeOutAttackTarget={timeOutAttackTarget}
						timeOutInEffect={timeOutInEffect}
					/>
				</ErrorBoundary>
			</main>
		</div>
	);
};

Party.displayName = "Party";
