import { functions } from "firebase/app";
import React from "react";
import { Modal, Image, Loader } from "semantic-ui-react";
import LocalTracks from "../av/LocalTracks";
import { captureException } from "@sentry/browser";
import { PourDrinkProps } from "../PourDrinkButton";
import { CapturedError } from "../util/CapturedError";
import loadingImage from "../images/logo-dark.png";

interface DetectionResults {
	productsDetected: string[];
	points: number;
}

function getCanvasBlob(canvas: HTMLCanvasElement, type: string): Promise<Blob> {
	return new Promise(function (resolve, reject) {
		canvas.toBlob(function (blob) {
			if (blob == null) {
				reject(`blob from canvas was null`);
			} else {
				resolve(blob);
			}
		}, type);
	});
}

async function getFrameCanvas(
	mediaStreamTrack: MediaStreamTrack,
): Promise<HTMLCanvasElement> {
	const imageCapture = new ImageCapture(mediaStreamTrack);
	const bitmap = await imageCapture.grabFrame();
	const canvas = document.createElement("canvas");
	canvas.width = bitmap.width;
	canvas.height = bitmap.height;

	const context = canvas.getContext("2d");
	if (context == null) {
		throw new Error(
			`canvas does not support getting the 2d context for drawing a bitmap`,
		);
	}

	context.drawImage(bitmap, 0, 0);
	return canvas;
}

async function notifyServer(partyId: string, roomId: string) {
	const pourADrinkFunction = functions().httpsCallable("pourADrink");
	const pouringArgs = { partyId, roomId };

	const result = await pourADrinkFunction(pouringArgs);

	return {
		pourId: result.data.pourId,
		imageUploadUrl: result.data.imageUploadUrl[0],
	};
}

async function getDetectionStatus(pourId: string) {
	const fn = functions().httpsCallable("pourADrinkStatus");
	const pouringArgs = { pourId };

	const result = await fn(pouringArgs);

	return {
		productsDetected: result.data.productsDetected as string[],
		points: result.data.points as number,
	};
}

async function uploadBlob(imageUploadUrl: string, image: Blob) {
	const buffer = await image.arrayBuffer();
	await fetch(imageUploadUrl, {
		method: "PUT",
		body: buffer,
		headers: { "Content-Type": "image/png" },
	});
}

async function getCanvasDetectionResults(
	partyId: string,
	roomId: string,
	canvas: HTMLCanvasElement,
): Promise<DetectionResults> {
	const notification = await notifyServer(partyId, roomId);
	const blob = await getCanvasBlob(canvas, "image/png");

	await uploadBlob(notification.imageUploadUrl, blob);

	return await getDetectionStatus(notification.pourId);
}

export function useDrinkPouring(
	partyId: string,
	roomId: string,
	getLocalTracks: () => LocalTracks | undefined,
	onError: (error: Error) => void,
) {
	const [isPouringADrink, setIsPouringADrink] = React.useState(false);
	const [canPourADrink, setCanPourADrink] = React.useState(false);
	const [showPourModal, setShowPourModal] = React.useState(false);
	const [detectionResults, setDetectionResults] = React.useState<
		DetectionResults
	>();
	const photoCanvasRef = React.useRef<HTMLCanvasElement>();

	const localTracks = getLocalTracks();

	React.useEffect(() => {
		setCanPourADrink(() => {
			return localTracks?.video.mediaStreamTrack.readyState === "live";
		});
	}, [localTracks]);

	const pourADrinkCallback = React.useCallback(() => {
		const videoTrack = localTracks?.video.mediaStreamTrack;
		if (videoTrack == null) {
			throw new Error(
				`missing video mediaStreamTrack...canPourADrink should be false`,
			);
		}
		setIsPouringADrink((currentlyPouring) => {
			if (currentlyPouring) {
				return currentlyPouring;
			}

			getFrameCanvas(videoTrack)
				.then((canvas) => {
					photoCanvasRef.current = canvas;
					setDetectionResults(undefined);
					setShowPourModal(true);
					return canvas;
				})
				.then((canvas) => getCanvasDetectionResults(partyId, roomId, canvas))
				.then((results) => setDetectionResults(results))
				.catch((err: Error) => {
					const eventId = captureException(err);
					const errorTitle = `Drink Pouring Issue`;
					const message = `Something went wrong pouring your drink, we're very sorry about that! Please try again. We've notified our team about the error.`;

					onError(new CapturedError(errorTitle, message, eventId));
					return undefined;
				})
				.finally(() => setIsPouringADrink(() => false));

			return true;
		});
	}, [localTracks, partyId, roomId, onError]);

	const hasImageCapture = window.ImageCapture != null;

	const pourDrinkButtonProps = React.useMemo((): PourDrinkProps | undefined => {
		if (!hasImageCapture) {
			return undefined;
		}

		return {
			canPourADrink,
			isPouringADrink,
			pourADrink: pourADrinkCallback,
		};
	}, [isPouringADrink, pourADrinkCallback, canPourADrink, hasImageCapture]);

	const drinkPourResultsModal = React.useMemo(() => {
		return (
			<PourDrinkModal
				show={showPourModal}
				canvas={photoCanvasRef.current}
				onClose={() => setShowPourModal(false)}
				detectionResults={detectionResults}
			/>
		);
	}, [showPourModal, detectionResults]);

	return {
		pourDrinkButtonProps,
		drinkPourResultsModal,
	};
}

type ModalProps = {
	show: boolean;
	onClose: () => void;
	canvas: HTMLCanvasElement | undefined;
	detectionResults: DetectionResults | undefined;
};

const PourDrinkModal: React.FC<ModalProps> = (props) => {
	let description;
	if (props.detectionResults == null) {
		description = <Loader active inline="centered" />;
	} else {
		const productsDetected = props.detectionResults.productsDetected;
		const points = props.detectionResults.points;
		if (productsDetected.length > 0) {
			description = (
				<p>
					Thanks for drinking {productsDetected[0]}! You got {points} points.
				</p>
			);
		} else {
			description = (
				<p>{`Glad you're having fun. Not sure what you're drinking though!`}</p>
			);
		}
	}

	const imgSource =
		props.canvas == null ? loadingImage : props.canvas.toDataURL();
	return (
		<Modal
			dimmer="inverted"
			closeIcon
			open={props.show}
			onClose={() => props.onClose()}
		>
			<Modal.Content image>
				<Image wrapped size="medium" src={imgSource} />
				<Modal.Description>{description}</Modal.Description>
			</Modal.Content>
		</Modal>
	);
};

PourDrinkModal.displayName = "PourDrinkModal";
