import React, { useEffect, useRef } from "react";
import Video from "twilio-video";

import styles from "./participant.scss";

import loadingImage from "./images/logo-dark.png";

type ParticipantAVProps = {
	videoTrack?: Video.VideoTrack;
	audioTrack?: Video.AudioTrack;
	isHighlighted: boolean;
	isMuted?: boolean;
	usePoster?: boolean;
};

export const ParticipantAV: React.FC<ParticipantAVProps> = (
	props: ParticipantAVProps,
) => {
	const videoRef = useRef<HTMLVideoElement>(null);
	const audioRef = useRef<HTMLAudioElement>(null);

	useEffect(() => {
		const videoTrack = props.videoTrack;
		if (videoTrack != null && videoRef.current != null) {
			videoTrack.attach(videoRef.current);
			return (): void => {
				videoTrack.detach();
			};
		}
	}, [props.videoTrack, videoRef.current]);

	useEffect(() => {
		const audioTrack = props.audioTrack;
		if (audioTrack != null && audioRef.current != null) {
			audioTrack.attach(audioRef.current);
			return (): void => {
				audioTrack.detach();
			};
		}
	}, [props.audioTrack, audioRef.current]);

	return (
		<>
			<video
				className={props.isHighlighted ? styles.highlighted : ""}
				ref={videoRef}
				autoPlay={true}
				poster={props.usePoster ?? true ? loadingImage : undefined}
			/>
			<audio ref={audioRef} autoPlay={true} muted={props.isMuted} />
		</>
	);
};

ParticipantAV.displayName = "ParticipantAV";
