import React, { useEffect, useState } from "react";
import { Container, Table, Form } from "semantic-ui-react";
import { functions } from "firebase/app";

interface InviteeRecord {
	uid: string;
	phoneNumber: string;
	invitedToMyParty: boolean;
}

const InviteAdmin: React.FC = () => {
	const [users, setUsers] = useState<InviteeRecord[]>();
	const [inviteePhoneNumber, setInviteePhoneNumber] = useState("");
	const [inviteePartyId, setInviteePartyId] = useState("adam-sidc");
	const [updateTrigger, setUpdateTrigger] = useState(0);

	useEffect(() => {
		const func = functions().httpsCallable("admin_list_users");
		func().then((results) => setUsers(results.data));
	}, [updateTrigger]);

	const submit = async () => {
		const func = functions().httpsCallable("admin_invite_user");
		const args = { phoneNumber: inviteePhoneNumber, partyId: inviteePartyId };
		await func(args);
		setUpdateTrigger((t) => t + 1);
	};

	let rows;
	if (users == null) {
		rows = (
			<Table.Row>
				<Table.Cell>Loading...</Table.Cell>
				<Table.Cell></Table.Cell>
			</Table.Row>
		);
	} else {
		rows = users.map((u) => {
			return (
				<Table.Row key={u.uid}>
					<Table.Cell>{u.uid}</Table.Cell>
					<Table.Cell>{u.phoneNumber}</Table.Cell>
					<Table.Cell>{u.invitedToMyParty ? "✅" : ""}</Table.Cell>
				</Table.Row>
			);
		});
	}
	return (
		<Container>
			<Form onSubmit={() => submit()}>
				<Form.Input
					label="Phone Number"
					onChange={(_e, data) => setInviteePhoneNumber(data.value)}
				/>
				<Form.Input
					label="Party ID"
					value={inviteePartyId}
					onChange={(_e, data) => setInviteePartyId(data.value)}
				/>
				<Form.Button content="Submit" />
			</Form>
			<Table>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>UID</Table.HeaderCell>
						<Table.HeaderCell>Phone Number</Table.HeaderCell>
						<Table.HeaderCell>Is Invited</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>{rows}</Table.Body>
			</Table>
		</Container>
	);
};

export { InviteAdmin };
