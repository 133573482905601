import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/browser";

import { FirebaseAppProvider } from "./firebase/FirebaseAppProvider";

import { ErrorBoundary } from "./ErrorBoundary";
import { Main } from "./Main";
import { SentryProvider } from "./util/SentryProvider";
import { FirebaseAuthStateProvider } from "./firebase/FirebaseAuthStateProvider";
import { LoginRequiredProvider } from "./firebase/LoginRequiredProvider";
import { Login } from "./Login";
import { Loader } from "semantic-ui-react";

Sentry.init({
	dsn: SENTRY_DSN,
	environment:
		window.location.hostname === "sidc.live" ? "production" : "development",
});

export const App: React.FC = () => {
	return (
		<FirebaseAppProvider firebaseConfig={JSON.parse(FIREBASE_FRONTEND_CONFIG)}>
			<FirebaseAuthStateProvider>
				<SentryProvider>
					<LoginRequiredProvider
						loginPage={<Login />}
						loading={<Loader active inline="centered" />}
					>
						<Main />
					</LoginRequiredProvider>
				</SentryProvider>
			</FirebaseAuthStateProvider>
		</FirebaseAppProvider>
	);
};

App.displayName = "App";

ReactDOM.render(
	<ErrorBoundary>
		<App />
	</ErrorBoundary>,
	document.getElementById("root"),
);
