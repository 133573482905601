export function configureFunctions(functions: firebase.functions.Functions) {
	if (FUNCTIONS_EMULATOR_CONFIG.defined === true) {
		let functionsEmulatorLocation: string;
		if (window.location.hostname === "localhost") {
			functionsEmulatorLocation = `http://localhost:${FUNCTIONS_EMULATOR_CONFIG.localPort}`;
		} else {
			functionsEmulatorLocation = FUNCTIONS_EMULATOR_CONFIG.remoteLocation;
		}
		console.log(
			`using firestore functions location ${functionsEmulatorLocation}`,
		);
		functions.useFunctionsEmulator(functionsEmulatorLocation);
	}
}

export function configureFirestore(firestore: firebase.firestore.Firestore) {
	if (FIRESTORE_EMULATOR_CONFIG.defined === true) {
		let firestoreEmulatorSettings;
		if (window.location.hostname === "localhost") {
			firestoreEmulatorSettings = {
				host: `localhost:${FIRESTORE_EMULATOR_CONFIG.localPort}`,
				ssl: false,
			};
		} else {
			firestoreEmulatorSettings = {
				host: FIRESTORE_EMULATOR_CONFIG.remoteLocation,
				ssl: true,
			};
		}
		console.log(
			`using firestore emulator location ${JSON.stringify(
				firestoreEmulatorSettings,
			)}`,
		);
		firestore.settings(firestoreEmulatorSettings);
	}
}
