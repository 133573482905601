import { functions } from "firebase/app";
import { useCallback, useMemo, useState, useEffect } from "react";

import {
	TimeOutAttackResult,
	AttackFailureReason,
} from "../../lib/attackTypes";
import React from "react";
import {
	Loader,
	Modal,
	Image,
	Segment,
	Header,
	Button,
} from "semantic-ui-react";
import { DateTime } from "luxon";

import punishmentSvg from "../images/punishment.svg";
import povertySvg from "../images/poverty.svg";
import timeoutSvg from "../images/timeout.svg";
import { captureException } from "@sentry/browser";
import { CapturedError } from "../util/CapturedError";
import { useFirestore } from "../firebase/FirebaseContext";
import { useUser } from "../firebase/UserContext";

export interface TimeOutEffect {
	timeout: TimeOutObject;
	release: () => void;
}

interface TimeOutObject {
	id: string;
	roomId: string;
	until: DateTime;
}

async function triggerTimeOut(
	partyId: string,
	roomId: string,
	targetUid: string,
) {
	const attackTimeOut = functions().httpsCallable("attack_timeout");
	const attackResult = await attackTimeOut({ partyId, roomId, targetUid });
	return attackResult.data as TimeOutAttackResult;
}
export function useTimingOut(
	partyId: string,
	roomId: string,
	onError: (error: Error) => void,
) {
	const [attackResults, setAttackResults] = useState<TimeOutAttackResult>();
	const [showModal, setShowModal] = React.useState(false);
	const [timeoutsInEffect, setTimeoutsInEffect] = React.useState<
		TimeOutObject[]
	>([]);

	const user = useUser();
	const db = useFirestore();

	useEffect(() => {
		return db
			.collection("parties")
			.doc(partyId)
			.collection("effects")
			.where("targetUid", "==", user.uid)
			.where("type", "==", "attack-timeout")
			.onSnapshot(
				(effectsSnap) => {
					const dbEffects = effectsSnap.docs.map((effectSnap):
						| TimeOutObject
						| undefined => {
						if (!effectSnap.exists) {
							return undefined;
						}
						const effect = effectSnap.data();
						const until = DateTime.fromSeconds(
							parseInt(effect.until.seconds, 10),
						);
						return {
							id: effectSnap.ref.path,
							roomId: effect.roomId as string,
							until,
						};
					});

					function notUndefined<T>(x: T | undefined): x is T {
						return x !== undefined;
					}

					setTimeoutsInEffect(dbEffects.filter(notUndefined));
				},
				(error) => {
					onError(error);
				},
			);
	}, [db, partyId, user.uid, onError]);

	const timeOutAttackTarget = useCallback(
		(targetUid: string) => {
			setAttackResults(undefined);
			setShowModal(true);
			triggerTimeOut(partyId, roomId, targetUid)
				.then((result) => {
					setAttackResults(result);
				})
				.catch((err: Error) => {
					const eventId = captureException(err);
					const errorTitle = `Timing Out Issue`;
					const message = `Something went wrong timing out this user, we're very sorry about that! Please try again. We've notified our team about the error.`;

					onError(new CapturedError(errorTitle, message, eventId));
					return undefined;
				});
		},
		[onError, partyId, roomId],
	);

	const timeOutResultModal = useMemo(() => {
		return (
			<TimeOutModal
				attackResults={attackResults}
				show={showModal}
				onClose={() => setShowModal(false)}
			/>
		);
	}, [attackResults, showModal]);

	const timeOutInEffect = useMemo(() => {
		const timeout = timeoutsInEffect
			.filter((e) => e.roomId === roomId)
			.sort((a, b) => a.until.toMillis() - b.until.toMillis())
			.pop();

		if (timeout == null) {
			return undefined;
		}

		return {
			timeout,
			release: async () => {
				await db.doc(timeout.id).delete();
				console.log(`removed effect ${timeout.id}`);
			},
		};
	}, [db, roomId, timeoutsInEffect]);

	return { timeOutAttackTarget, timeOutResultModal, timeOutInEffect };
}

type ModalProps = {
	show: boolean;
	onClose: () => void;
	attackResults: TimeOutAttackResult | undefined;
};

const TimeOutModal: React.FC<ModalProps> = (props) => {
	let content;
	if (props.attackResults == null) {
		content = (
			<Modal.Content>
				<Loader active inline="centered" />
			</Modal.Content>
		);
	} else {
		if (props.attackResults.success) {
			content = (
				<Modal.Content image>
					<Image wrapped size="medium" src={punishmentSvg} />
					<Modal.Description>
						<p>Punishment has been applied.</p>
					</Modal.Description>
				</Modal.Content>
			);
		} else {
			switch (props.attackResults.reason) {
				case AttackFailureReason.INSUFFICIENT_POINTS:
					content = (
						<Modal.Content image>
							<Image wrapped size="medium" src={povertySvg} />
							<Modal.Description>
								<p>You cannot afford to do this. Collect some more points.</p>
							</Modal.Description>
						</Modal.Content>
					);
			}
		}
	}

	return (
		<Modal
			dimmer="inverted"
			closeIcon
			open={props.show}
			onClose={() => props.onClose()}
		>
			<Modal.Header>Go To Timeout!</Modal.Header>
			{content}
		</Modal>
	);
};

TimeOutModal.displayName = "TimeOutModal";

type TimeOutInEffectMessageProps = {
	timeOutInEffect: TimeOutEffect;
};

export const TimeOutInEffectMessage: React.FC<TimeOutInEffectMessageProps> = (
	props,
) => {
	const [_time, setTime] = useState(0);
	useEffect(() => {
		// update every second to rerender as it counts doown
		const interval = setInterval(() => setTime(Date.now()), 1000);
		return () => clearInterval(interval);
	});

	const timeOutInEffect = props.timeOutInEffect;
	const timeout = timeOutInEffect.timeout;
	const stillInEffect = timeout.until < DateTime.utc();
	return (
		<Segment placeholder textAlign="center">
			<Header icon>
				<Image src={timeoutSvg} />
				{`I've been attacked!`}
			</Header>
			<Segment.Inline>
				<p>
					Someone spent their hard earned points to kick you out of this room
					and put you in time out. You can go to another room, or wait for this
					attack to expire and come back in.
				</p>
				<p>Punishment Expiration: {timeout.until.toRelative()}</p>
				<Button
					positive
					disabled={!stillInEffect}
					onClick={() => timeOutInEffect.release()}
				>
					Release Me!
				</Button>
			</Segment.Inline>
		</Segment>
	);
};
