import { createContext, useContext } from "react";

interface UserContextType {
	user: firebase.User;
}

const UserContext = createContext<UserContextType | undefined>(undefined);
export const UserContextProvider = UserContext.Provider;

const useUserContext = () => {
	const context = useContext(UserContext);
	if (context == null) {
		throw new Error(`attempting to use UserContext outside consumer`);
	}

	return context;
};

export const useUser = () => useUserContext().user;
