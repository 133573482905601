import React, { useCallback } from "react";

import Video from "twilio-video";

import { LocalParticipant } from "./LocalParticipant";
import { MapComponent } from "./Map";
import { Loader } from "semantic-ui-react";
import { PartyGoerEmbodiment } from "./PartyGoerEmbodiment";
import { PartyGoer } from "./PartyGoer";
import { useWhispering, isWhisperMessage } from "./games/whispering";
import useVirtualRoom from "./av/useVirtualRoom";
import { useUser } from "./firebase/UserContext";

import LocalTracks from "./av/LocalTracks";
import { TimeOutEffect, TimeOutInEffectMessage } from "./games/timingOut";

type Props = {
	roomId: string;
	token: string | undefined;
	getLocalTracks: () => LocalTracks | undefined;
	timeOutAttackTarget: (targetUid: string) => void;
	timeOutInEffect: TimeOutEffect | undefined;
};

export const Room: React.FC<Props> = (props) => {
	const user = useUser();

	const [error, setError] = React.useState<Error>();
	if (error != null) {
		throw error;
	}

	const onError = React.useCallback((error: Error) => setError(error), []);

	const [partyGoers, setPartyGoers] = React.useState<PartyGoer[]>([]);
	const { connect, isConnected, localNetworkQualityLevel } = useVirtualRoom(
		onError,
	);

	const {
		onWhisperChangeRooms,
		onWhisperParticipantDisconnected,
		stopWhispering,
		startWhispering,
		onWhisperMessageReceived,
		...whispering
	} = useWhispering(user.uid);

	const { roomId, token, timeOutInEffect } = props;

	const localTracks = props.getLocalTracks();

	React.useEffect(() => {
		if (localTracks == null || token == null) {
			return () => {
				// noop
			};
		}

		setPartyGoers([]);
		onWhisperChangeRooms();

		if (timeOutInEffect != null) {
			return () => {
				// noop
			};
		}

		const participantConnected = (
			participant: Video.RemoteParticipant,
		): void => {
			const id = participant.identity;
			console.log(`participant ${id} connecting`);
			setPartyGoers((prevPartyGoers) => [
				...prevPartyGoers,
				{ id, participant },
			]);
		};

		const participantDisconnected = (
			participant: Video.RemoteParticipant,
		): void => {
			const id = participant.identity;
			console.log(`participant ${id} disconnecting`);
			setPartyGoers((prevPartyGoers) =>
				prevPartyGoers.filter((p) => p.id !== id),
			);
			onWhisperParticipantDisconnected(id);
		};

		return connect(
			token,
			roomId,
			localTracks,
			participantConnected,
			participantDisconnected,
		);
	}, [
		connect,
		localTracks,
		onWhisperChangeRooms,
		onWhisperParticipantDisconnected,
		roomId,
		token,
		timeOutInEffect,
	]);

	const onPartyGoerLongPressStart = useCallback(
		(partyGoerId: string) => {
			const sendDataMessage =
				localTracks == null
					? undefined
					: (msg: string) => localTracks.data.send(msg);
			startWhispering(partyGoerId, sendDataMessage);
		},
		[localTracks, startWhispering],
	);

	const onPartyGoerLongPressEnd = useCallback(
		(_partyGoerId: string) => {
			const sendDataMessage =
				localTracks == null
					? undefined
					: (msg: string) => localTracks.data.send(msg);
			stopWhispering(sendDataMessage);
		},
		[localTracks, stopWhispering],
	);

	const onPartyGoerMessageReceived = useCallback(
		(partyGoerId: string, message: string) => {
			console.log(`received ${message} from ${partyGoerId}`);
			const data = JSON.parse(message);
			if (isWhisperMessage(data)) {
				onWhisperMessageReceived(data, partyGoerId);
			}
		},
		[onWhisperMessageReceived],
	);

	const partyGoerEmboidments = partyGoers.map((partyGoer) => {
		const whisperingToThem = whispering.amIWhisperingTo(partyGoer.id);

		const theirWhisper = whispering.whoAreTheyWhisperingTo(partyGoer.id);
		const whisperingAtMe = theirWhisper === "me";
		const whisperingAtSomeoneElse =
			theirWhisper !== "nobody" && theirWhisper !== "me";

		return (
			<PartyGoerEmbodiment
				key={partyGoer.id}
				partyGoer={partyGoer}
				onDataMessageReceived={onPartyGoerMessageReceived}
				isMuted={whisperingAtSomeoneElse}
				isHighlighted={whisperingAtMe || whisperingToThem}
				onLongPressStart={onPartyGoerLongPressStart}
				onLongPressEnd={onPartyGoerLongPressEnd}
				timeOutTarget={props.timeOutAttackTarget}
			/>
		);
	});

	if (timeOutInEffect != null) {
		return <TimeOutInEffectMessage timeOutInEffect={timeOutInEffect} />;
	}

	if (!isConnected) {
		return <Loader active inline="centered" />;
	}

	return (
		<>
			<MapComponent>
				<LocalParticipant
					videoTrack={localTracks?.video}
					networkQualityLevel={localNetworkQualityLevel}
				/>
				{partyGoerEmboidments}
			</MapComponent>
		</>
	);
};

Room.displayName = "Room";
