import React from "react";

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import { configureFirestore, configureFunctions } from "./initFirebase";
import { FirebaseContextProvider } from "./FirebaseContext";

type Props = {
	// eslint-disable-next-line @typescript-eslint/ban-types
	firebaseConfig: Object;
};

export const FirebaseAppProvider: React.FC<Props> = (props) => {
	const app = firebase.initializeApp(props.firebaseConfig);

	const auth = firebase.auth(app);
	const firestore = firebase.firestore(app);
	const functions = firebase.functions(app);

	configureFirestore(firestore);
	configureFunctions(functions);

	return (
		<FirebaseContextProvider value={{ app, auth, firestore, functions }}>
			{props.children}
		</FirebaseContextProvider>
	);
};
FirebaseAppProvider.displayName = "FirebaseAppProvider";
