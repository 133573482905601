import { useCallback, useState, useEffect, useRef } from "react";
import Video, { NetworkQualityLevel, NetworkQualityStats } from "twilio-video";
import { captureException } from "@sentry/browser";
import { CapturedError } from "../util/CapturedError";
import LocalTracks from "./LocalTracks";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Listener = (...args: any[]) => void;

const preferredVideoCodec: Video.VP8CodecSettings = {
	codec: "VP8",
	simulcast: true,
};

interface VirtualRoom {
	room: Video.Room;
	roomListeners: Map<string, Listener>;
	localParticipantListeners: Map<string, Listener>;
	beforeUnloadHandler: Listener;
}

function leaveRoom(room: Video.Room): void {
	if (room.localParticipant.state === "connected") {
		const tracks = room.localParticipant.tracks;
		for (const [_sid, trackPublication] of tracks) {
			console.log(`unpublishing ${trackPublication.kind}`);
			trackPublication.unpublish();
		}

		room.disconnect();
		console.log(`left room ${room.name}`);
	}
}

async function connectToRoom(
	token: string,
	roomId: string,
	localTracks: LocalTracks,
	onParticipantConnected: (participant: Video.RemoteParticipant) => void,
	onParticipantDisconnected: (participant: Video.RemoteParticipant) => void,
	setLocalNetworkQualityLevel: (quality: number) => void,
): Promise<VirtualRoom> {
	const room = await Video.connect(token, {
		name: roomId,
		preferredVideoCodecs: [preferredVideoCodec],
		tracks: [localTracks.audio, localTracks.video, localTracks.data],
		networkQuality: {
			local: 2,
			remote: 2,
		},
	});

	const beforeUnloadHandler = () => leaveRoom(room);
	window.addEventListener("beforeunload", beforeUnloadHandler);

	const roomListeners = new Map<string, Listener>();

	room.on("participantConnected", onParticipantConnected);
	roomListeners.set("participantConnected", onParticipantConnected);

	room.on("participantDisconnected", onParticipantDisconnected);
	roomListeners.set("participantDisconnected", onParticipantDisconnected);

	// TODO fix race condition. Maybe an observable instead
	room.participants.forEach(onParticipantConnected);

	const localParticipantListeners = new Map<string, Listener>();

	const onTrackPublished = (publication: Video.LocalTrackPublication) => {
		console.log(`published ${publication.kind} track`);
	};

	room.localParticipant.on("trackPublished", onTrackPublished);
	localParticipantListeners.set("trackPublished", onTrackPublished);

	const onTrackPublishFailed = (
		error: Video.TwilioError,
		localTrack: Video.LocalTrack,
	): void => {
		const eventId = captureException(error);
		alert(
			`issue connecting to the room...other participants might not be getting the ${localTrack.kind} that you send [event id: ${eventId}]`,
		);
	};

	room.localParticipant.on("trackPublicationFailed", onTrackPublishFailed);
	localParticipantListeners.set("trackPublicationFailed", onTrackPublishFailed);

	const onLocalNetworkQualityLevelChanged = (
		networkQualityLevel: NetworkQualityLevel,
		_networkQualityStats: NetworkQualityStats | undefined,
	): void => {
		setLocalNetworkQualityLevel(networkQualityLevel);
	};

	room.localParticipant.on(
		"networkQualityLevelChanged",
		onLocalNetworkQualityLevelChanged,
	);
	localParticipantListeners.set(
		"networkQualityLevelChanged",
		onLocalNetworkQualityLevelChanged,
	);

	return {
		room,
		roomListeners,
		localParticipantListeners,
		beforeUnloadHandler,
	};
}

function cleanupRoom(virtualRoom: VirtualRoom) {
	const room = virtualRoom.room;

	const roomListeners = virtualRoom.roomListeners;
	roomListeners.forEach((listener: Listener, key: string) =>
		room.off(key, listener),
	);

	const localParticipantListeners = virtualRoom.localParticipantListeners;
	localParticipantListeners.forEach((listener: Listener, key: string) =>
		room.localParticipant.off(key, listener),
	);

	leaveRoom(virtualRoom.room);

	window.removeEventListener("beforeunload", virtualRoom.beforeUnloadHandler);
}

export default function useVirtualRoom(onError: (error: Error) => void) {
	const connectedRoomPromise = useRef<Promise<VirtualRoom | undefined>>(
		Promise.resolve(undefined),
	);

	const [isConnected, setIsConnected] = useState(false);
	const [localNetworkQualityLevel, setLocalNetworkQualityLevel] = useState(0);

	const updateIsConnected = (room: VirtualRoom | undefined) => {
		setIsConnected(room != null);
		return room;
	};

	const connect = useCallback(
		(
			token: string,
			roomId: string,
			localTracks: LocalTracks,
			onParticipantConnected: (participant: Video.RemoteParticipant) => void,
			onParticipantDisconnected: (participant: Video.RemoteParticipant) => void,
		) => {
			const currentConnectionPromise = connectedRoomPromise.current;
			connectedRoomPromise.current = currentConnectionPromise
				.then((virtualRoom) => {
					if (virtualRoom != null) {
						cleanupRoom(virtualRoom);
					}

					return undefined;
				})
				.then(updateIsConnected)
				.then(() => {
					return connectToRoom(
						token,
						roomId,
						localTracks,
						onParticipantConnected,
						onParticipantDisconnected,
						setLocalNetworkQualityLevel,
					).catch((error: Error) => {
						const eventId = captureException(error);
						onError(
							new CapturedError(
								`Unable to Connect`,
								`We had an issue connecting to your room: ${error.message}`,
								eventId,
							),
						);

						return undefined;
					});
				})
				.then(updateIsConnected);

			return () =>
				connectedRoomPromise.current
					.then((virtualRoom) => {
						if (virtualRoom != null) {
							cleanupRoom(virtualRoom);
						}

						return undefined;
					})
					.then(updateIsConnected);
		},
		[onError],
	);

	useEffect(() => {
		return () => {
			console.log("unloading useVirtualRoom");
			connectedRoomPromise.current.then((virtualRoom) => {
				if (virtualRoom != null) {
					cleanupRoom(virtualRoom);
				}

				return undefined;
			});
		};
	}, []);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(window as any).RoomPromiseRef = connectedRoomPromise;
	}, []);

	return { connect, isConnected, localNetworkQualityLevel };
}
