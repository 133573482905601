import React, { useState } from "react";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";

import smokeBreakSvg from "./images/smoke-break.svg";
import { useFirestore } from "./firebase/FirebaseContext";

interface Room {
	id: string;
	displayName: string;
	twilioId: string;
	numParticipants: number;
}

export type RoomSelectorProps = {
	partyId: string;
	leaveParty: () => void;
	roomId: string;
	setRoomId: (roomId: string) => void;
};

export const RoomSelector: React.FC<RoomSelectorProps> = (props) => {
	const [rooms, setRooms] = useState<Room[]>();

	const db = useFirestore();

	const partyId = props.partyId;

	React.useEffect(() => {
		return db
			.collection("parties")
			.doc(partyId)
			.collection("rooms")
			.orderBy("sortOrder")
			.onSnapshot((roomSnap) => {
				const roomsLoaded = roomSnap.docs.map<Room>((room) => {
					const data = room.data();
					return {
						id: room.id,
						displayName: data.displayName,
						twilioId: data.twilioId,
						numParticipants: data.numParticipants,
					};
				});

				setRooms(roomsLoaded);
			});
	}, [partyId, db]);

	if (!rooms) {
		return <></>;
	}

	const options = rooms.map<DropdownItemProps>((r) => {
		return {
			key: r.id,
			text: r.displayName,
			value: r.twilioId,
			description: r.numParticipants,
		};
	});
	options.push({
		key: "__smoke_break__",
		text: "Leave the Party",
		value: "__smoke_break__",
		image: { src: smokeBreakSvg },
	});

	return (
		<span>
			Drinking in the:{" "}
			<Dropdown
				options={options}
				inline
				value={props.roomId}
				onChange={(_event, data) => {
					const value = data.value;
					if (value === "__smoke_break__") {
						props.leaveParty();
						return;
					}

					props.setRoomId(value as string);
				}}
			></Dropdown>
		</span>
	);
};

RoomSelector.displayName = "RoomSelector";
