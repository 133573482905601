import React from "react";
import { InvitationStatus } from "../../lib/invitationTypes";
import { Button, Icon } from "semantic-ui-react";

type InvitationButtonProps = {
	invitationStatus: InvitationStatus;
	acceptingInvite: boolean;
	joinParty: () => void;
	acceptInvitation: () => void;
};

export const InvitationButton: React.FunctionComponent<InvitationButtonProps> = (
	props: InvitationButtonProps,
) => {
	if (props.acceptingInvite) {
		return <Button fluid positive content="Accept Invitation" loading />;
	}

	switch (props.invitationStatus) {
		case InvitationStatus.EXTENDED:
			return (
				<Button
					fluid
					positive
					content="Accept Invitation"
					onClick={(): void => props.acceptInvitation()}
				/>
			);
		case InvitationStatus.ACCEPTED:
			return (
				<Button
					fluid
					primary
					content="Join Party!"
					onClick={(): void => props.joinParty()}
				/>
			);
		case InvitationStatus.REQUESTED:
			return (
				<Button fluid disabled>
					<Icon name="circle notched" loading />
					Waiting on host...
				</Button>
			);
	}
};

InvitationButton.displayName = "InvitationButton";
