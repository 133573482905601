import React from "react";
import { Invitation, ExtendedInvitation } from "../../lib/invitationTypes";
import { Card } from "semantic-ui-react";
import { acceptInvitation } from "./invitationQueries";
import { InvitationButton } from "./InvitationButton";
import { useFirestore } from "../firebase/FirebaseContext";

type InvitationCardProps = {
	invitation: Invitation;
	enterParty: (partyId: string) => void;
};

export const InvitationCard: React.FunctionComponent<InvitationCardProps> = (
	props: InvitationCardProps,
) => {
	const [partyInfo, setPartyInfo] = React.useState({
		name: "Loading...",
		description: "",
	});
	const [acceptingInvite, setAcceptingInvite] = React.useState(false);

	const db = useFirestore();
	React.useEffect(() => {
		const partyRef = db.collection("parties").doc(props.invitation.partyId);
		partyRef.onSnapshot((snap) => {
			const partyData = snap.data();
			if (partyData == null) {
				setPartyInfo({
					name: "Error",
					description: "Unable to locate this party!",
				});
				return;
			}

			setPartyInfo({
				name: partyData.name,
				description: partyData.description,
			});
		});
	}, [db, props.invitation.partyId]);

	const acceptInvite = async (): Promise<void> => {
		setAcceptingInvite(true);
		const extendedInvitation = props.invitation as ExtendedInvitation;
		if (!extendedInvitation) {
			throw new Error(
				`attempting to accept and invitation that isn't extended: ${props.invitation.invitationRef}`,
			);
		}

		await acceptInvitation(db, extendedInvitation);
		setAcceptingInvite(false);
	};

	return (
		<Card>
			<Card.Content>
				<Card.Header>{partyInfo.name}</Card.Header>
				<Card.Description>{partyInfo.description}</Card.Description>
			</Card.Content>
			<Card.Content extra>
				<InvitationButton
					invitationStatus={props.invitation.status}
					acceptingInvite={acceptingInvite}
					joinParty={(): void => props.enterParty(props.invitation.partyId)}
					acceptInvitation={acceptInvite}
				/>
			</Card.Content>
		</Card>
	);
};

InvitationCard.displayName = "InvitationCard";
