import React from "react";
import { Invitation } from "../../lib/invitationTypes";
import { Message, Card, Loader } from "semantic-ui-react";
import { InvitationCard } from "./InvitationCard";
import { parseSnapshot } from "./invitationQueries";
import { useUser } from "../firebase/UserContext";
import { useFirestore } from "../firebase/FirebaseContext";
import styles from "./invitations.scss";

type Props = {
	enterParty: (partyId: string) => void;
};
export const InvitationList: React.FC<Props> = (props) => {
	const [invitations, setInvitations] = React.useState<Invitation[]>();
	const user = useUser();
	const db = useFirestore();

	React.useEffect(() => {
		const collectionRef = db
			.collectionGroup("invitations")
			.where("phoneNumber", "==", user.phoneNumber);
		return collectionRef.onSnapshot((snap) => {
			const invitationObjects = snap.docs.map((i) => parseSnapshot(i));
			setInvitations(invitationObjects);
		});
	}, [db, user.phoneNumber]);

	if (invitations == null) {
		return <Loader active inline="centered" />;
	}

	if (!invitations.length) {
		return (
			<Message className={styles.outer}>
				<Message.Content>
					<Message.Header>Awaiting Invite</Message.Header>
					This message should disappear quickly. Message Adam if it does not.
				</Message.Content>
			</Message>
		);
	}

	const invitationCards = invitations.map((invitation) => (
		<InvitationCard
			key={invitation.invitationRef}
			invitation={invitation}
			enterParty={props.enterParty}
		/>
	));

	return (
		<div className={styles.outer}>
			<Card.Group>{invitationCards}</Card.Group>
		</div>
	);
};

InvitationList.displayName = "InvitationList";
