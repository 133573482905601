import React, { useState, useEffect } from "react";
import { Dropdown, Image } from "semantic-ui-react";

import accountMenuSvg from "./images/account-menu.svg";
import { useAuth } from "./firebase/FirebaseContext";
import { useUserState } from "./firebase/AuthStateContext";
// import signOutSvg from "./images/sign-out.svg";

type Props = {
	toggleAdminView?: () => void;
};

export const UserMenu: React.FC<Props> = (props) => {
	const auth = useAuth();
	const userState = useUserState();
	const [showAdminToggle, setShowAdminToggle] = useState(false);

	useEffect(() => {
		if (userState === "anonymous" || userState === "loading") {
			return;
		}

		userState.getIdTokenResult().then((tokenResult) => {
			if (tokenResult.claims.admin === true) {
				setShowAdminToggle(true);
			}
		});
	}, [userState]);

	if (userState === "anonymous" || userState === "loading") {
		return <></>;
	}

	const trigger = <Image avatar src={accountMenuSvg}></Image>;

	const adminToggle = showAdminToggle ? (
		<Dropdown.Item onClick={() => props.toggleAdminView?.()}>
			<span>Toggle Admin View</span>
		</Dropdown.Item>
	) : undefined;

	return (
		<Dropdown item trigger={trigger} simple>
			<Dropdown.Menu>
				{adminToggle}
				<Dropdown.Item
					onClick={() => {
						if (
							confirm(
								"Sign out completely? Take a smoke break if you just want to leave the party. Menu right next door.",
							)
						) {
							auth.signOut();
						}
					}}
				>
					{/* <Image avatar src={signOutSvg}></Image> */}
					<span>Sign Out</span>
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
};

UserMenu.displayName = "UserMenu";
