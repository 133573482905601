import React from "react";
import * as Sentry from "@sentry/browser";
import { useAuth } from "../firebase/FirebaseContext";

export const SentryProvider: React.FC = (props) => {
	const auth = useAuth();

	React.useEffect(() => {
		return auth.onAuthStateChanged((user) => {
			Sentry.configureScope((scope) => scope.setUser({ id: user?.uid }));

			if (user != null) {
				const phoneNumber = user.phoneNumber;
				if (phoneNumber == null) {
					throw new Error(`user ${user.uid} doesn't have a phone number`);
				}
			}
		});
	}, [auth]);

	return <>{props.children}</>;
};

SentryProvider.displayName = "SentryProvider";
