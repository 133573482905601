import React from "react";
import { Message } from "semantic-ui-react";
import DeviceOrientation, {
	Orientation,
} from "@montasaurus/react-screen-orientation";

import { Header } from "./Header";

import appStyles from "./app.scss";
import { InvitationList } from "./invitations/InvitationList";

type Props = {
	enterParty: (partyId: string) => void;
	toggleAdminView: () => void;
};
const Lobby: React.FC<Props> = (props) => {
	const [showPortraitWarning, setshowPortraitWarning] = React.useState(true);

	return (
		<div className={appStyles.app}>
			<Header toggleAdminView={props.toggleAdminView} />
			<main>
				<DeviceOrientation>
					<Orientation
						className={appStyles.tooSmall}
						alwaysRender={false}
						orientation="portrait"
					>
						{showPortraitWarning ? (
							<Message
								warning
								onDismiss={() => setshowPortraitWarning(false)}
								header="Turn your phone sideways"
								content="It'll make it easier to see everyone."
							/>
						) : (
							""
						)}
					</Orientation>
				</DeviceOrientation>
				<InvitationList enterParty={props.enterParty} />
			</main>
		</div>
	);
};

export { Lobby };
