import React from "react";
import { Button, Image } from "semantic-ui-react";

import takePictureSvg from "./images/take-picture.svg";

export type PourDrinkProps = {
	canPourADrink: boolean;
	pourADrink: () => void;
	isPouringADrink: boolean;
};
export const PourDrinkButton: React.FC<PourDrinkProps> = (props) => {
	const { canPourADrink, isPouringADrink, pourADrink } = props;

	if (!canPourADrink) {
		return <></>;
	}

	return (
		<Button basic disabled={isPouringADrink} onClick={() => pourADrink()}>
			<Image src={takePictureSvg} avatar />
			<span>Pour a Drink</span>
		</Button>
	);
};

PourDrinkButton.displayName = "PourDrinkButton";
