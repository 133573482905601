// A host has extended an invitatation to a guest
export interface ExtendedInvitation {
	invitationRef: string;
	status: InvitationStatus.EXTENDED;
	partyId: string;
}

// Either a host has extended an invitation and a guest has accepted, or a guest has requested and a host has granted an invitation
export interface AcceptedInvitation {
	invitationRef: string;
	status: InvitationStatus.ACCEPTED;
	partyId: string;
}

// A guest has requested an invitation to a party
export interface RequestedInvitation {
	invitationRef: string;
	status: InvitationStatus.REQUESTED;
	partyId: string;
}

export type Invitation =
	| ExtendedInvitation
	| AcceptedInvitation
	| RequestedInvitation;

export enum InvitationStatus {
	EXTENDED = "extended",
	ACCEPTED = "accepted",
	REQUESTED = "requested",
}
