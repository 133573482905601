import React from "react";
import { Menu } from "semantic-ui-react";

import styles from "./header.scss";
import { RoomSelector, RoomSelectorProps } from "./RoomSelector";
import { UserMenu } from "./UserMenu";
import { PourDrinkButton, PourDrinkProps } from "./PourDrinkButton";
import { PointsMenu } from "./PointsMenu";

type Props = {
	pourDrink?: PourDrinkProps;
	roomSelector?: RoomSelectorProps;
	toggleAdminView?: () => void;
};
export const Header: React.FC<Props> = (props) => {
	return (
		<header>
			<Menu borderless>
				<Menu.Item className={styles.brand}>
					<h1 title="No Touching!">Social Isolation Drinking Club</h1>
				</Menu.Item>
				<Menu.Menu position="right">
					<Menu.Item>
						{props.roomSelector != null ? (
							<RoomSelector {...props.roomSelector} />
						) : (
							""
						)}
					</Menu.Item>
					<Menu.Item>
						{props.pourDrink != null ? (
							<PourDrinkButton {...props.pourDrink}></PourDrinkButton>
						) : (
							""
						)}
					</Menu.Item>
					<PointsMenu />
					<UserMenu toggleAdminView={props.toggleAdminView} />
				</Menu.Menu>
			</Menu>
		</header>
	);
};

Header.displayName = "Header";
