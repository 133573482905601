import { createContext, useContext } from "react";

interface ContextType {
	userState: "loading" | "anonymous" | firebase.User;
}

const AuthStateContext = createContext<ContextType>({ userState: "loading" });
export const AuthStateContextProvider = AuthStateContext.Provider;

const useAuthStateContext = () => {
	const context = useContext(AuthStateContext);
	if (context == null) {
		throw new Error(`attempting to use AuthStateContext outside consumer`);
	}

	return context;
};

export const useUserState = () => useAuthStateContext().userState;
