import { createContext, useContext } from "react";

interface FirebaseContextType {
	app: firebase.app.App;
	auth: firebase.auth.Auth;
	firestore: firebase.firestore.Firestore;
	functions: firebase.functions.Functions;
}

const FirebaseContext = createContext<FirebaseContextType | undefined>(
	undefined,
);
export const FirebaseContextProvider = FirebaseContext.Provider;

const useFirebaseContext = () => {
	const context = useContext(FirebaseContext);

	if (context == null) {
		throw new Error(`attempting to use FirebaseContext outside consumer`);
	}

	return context;
};

export const useAuth = () => useFirebaseContext().auth;
export const useFirestore = () => useFirebaseContext().firestore;
